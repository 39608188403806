const origin = window.location.origin

const findUrl = function () {
  const currentUrl = window.location.hostname
  const portUrl = window.location.port
  if (portUrl) {
    return 'http://' + currentUrl + ':' + portUrl
  } else {
    return 'https://' + currentUrl
  }
}

const callbackUrl = function () {
  const origin = window.location.origin
  const search = window.location.search
  return `${origin}/callback${search}`
}

const logoutUrl = () => {
  return `${origin}/`
}

export const HELPERS = {
  currentUrl: findUrl(),
  callbackUrl: callbackUrl(),
  logoutUrl: logoutUrl(),
}
