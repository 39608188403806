<template>
  <button class="p-0">
    <div class="profile-image-placeholder">
      <img
        id="profileImg"
        :alt="$t('Generic.NavBar.ProfilePlaceHolderImageAlt')"
        class="profile-image position-relative"
        src="@/assets/images/profile.svg"
      />
    </div>
  </button>
</template>

<style>
button {
  border: none;
  background: none;
}

.profile-image-placeholder {
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;
  background-color: var(--light-grey);
  border-radius: 100%;
  transition: background-color 0.2s ease;
}

.profile-image-placeholder:hover {
  cursor: pointer;
  background-color: var(--mid-grey);
}

.username .profile-image-placeholder:hover {
  background-color: var(--light-grey);
  cursor: default;
}

.profile-image {
  top: 10px;
  left: 0.5px;
  width: 18px;
}
</style>
