import difference from 'lodash/difference'
import {
  RESET_DONATION_SOURCE_SETUP,
  SET_IS_ADDING_NEW_SOURCE,
  SET_DONATION_SOURCE_PROVIDER,
  SET_PAGES,
  SET_PROVIDER_PAGES,
  SET_PROGRESS_METER_VISIBILITY,
  SET_CURRENT_PAGE,
  SET_NEXT_BUTTON_IS_DISABLED,
  SET_ERROR,
  HIDE_ALL_PAGES,
  SHOW_ELIGIBILITY_CHECK_PAGE,
  SHOW_DETAILS_PAGE,
  SHOW_AUTHORISE_PAGE,
  SHOW_CONFIRMATION_PAGE,
  CONFIRM_ELIGIBILITY_CHECK,
  CONFIRM_DETAILS,
  CONFIRM_AUTHORISE,
  CONFIRM_CONFIRMED,
  SET_IS_ELIGIBLE,
  SET_DETAILS_REFERENCE,
  SET_AUTHORISER,
} from '@/store/mutation-types'
import { ALL_PAGES, defaultState } from './index'

type State = {
  isAddingNewSource: boolean
  providerConfig: any
  donationSourceProvider: string
  donationSourceProviderMeta: {
    slug: string
    translationKey: string
    logo: string
    brandColor: string
    inputPlaceholder: string
    inputValidation: any
    inputMask: any
    upperCase: boolean
    informationShareList: any
    referenceByHmrcCustomerId: boolean
  }
  excludedPages: string[]
  helpPage: string
  pages: string[]
  showProgressMeter: boolean
  currentPage: string
  nextButtonIsDisabled: boolean
  error: boolean
  errorMessage: string
  errorCode: string
  page: {
    eligibilityCheck: { visible: boolean; confirmed: boolean }
    details: { visible: boolean; confirmed: boolean }
    authorise: { visible: boolean; confirmed: boolean }
    confirmation: { visible: boolean; confirmed: boolean }
  }
  meta: {
    eligibilityCheck: { hasChosen: boolean; isEligible: boolean }
    details: { reference: string }
    authorise: { authoriser: string }
  }
}

export default {
  [RESET_DONATION_SOURCE_SETUP](state: State) {
    Object.assign(state, defaultState())
  },

  [SET_IS_ADDING_NEW_SOURCE](state: State, payload: boolean) {
    state.isAddingNewSource = payload
  },

  [SET_DONATION_SOURCE_PROVIDER](state: State, provider: any) {
    state.providerConfig = provider
    state.donationSourceProvider = provider.name
    state.donationSourceProviderMeta.slug = provider.slug || ''
    state.donationSourceProviderMeta.translationKey =
      provider.translationKey || ''
    state.donationSourceProviderMeta.logo = provider.logo
    state.donationSourceProviderMeta.brandColor =
      provider.brandColor || '#07c6d2'
    state.donationSourceProviderMeta.inputPlaceholder =
      provider.inputPlaceholder
    state.donationSourceProviderMeta.inputValidation = provider.inputValidation
    state.donationSourceProviderMeta.inputMask = provider.inputMask
    state.donationSourceProviderMeta.upperCase = provider.upperCase || false
    state.donationSourceProviderMeta.informationShareList =
      provider.informationShareList || {}
    state.excludedPages = provider.excludePages || []
    state.helpPage = provider.helpPage
    state.donationSourceProviderMeta.referenceByHmrcCustomerId =
      provider.referenceByHmrcCustomerId || false
  },

  [SET_PAGES](state: State, array: string[]) {
    state.pages = array
  },

  [SET_PROVIDER_PAGES](state: State) {
    state.pages = difference(ALL_PAGES, state.excludedPages)
  },

  [SET_PROGRESS_METER_VISIBILITY](state: State, visibility: boolean) {
    state.showProgressMeter = visibility
  },

  [SET_CURRENT_PAGE](state: State, page: string) {
    state.currentPage = page
  },

  [SET_NEXT_BUTTON_IS_DISABLED](state: State, status: boolean) {
    state.nextButtonIsDisabled = status
  },

  [SET_ERROR](
    state: State,
    payload: { status: boolean; message: string; code: string }
  ) {
    state.error = payload.status
    state.errorMessage = payload.message
    state.errorCode = payload.code
  },

  [HIDE_ALL_PAGES](state: State) {
    state.page.eligibilityCheck.visible = false
    state.page.details.visible = false
    state.page.authorise.visible = false
    state.page.confirmation.visible = false
  },

  [SHOW_ELIGIBILITY_CHECK_PAGE](state: State, visibility: boolean) {
    state.page.eligibilityCheck.visible = visibility
  },

  [SHOW_DETAILS_PAGE](state: State, visibility: boolean) {
    state.page.details.visible = visibility
  },

  [SHOW_AUTHORISE_PAGE](state: State, visibility: boolean) {
    state.page.authorise.visible = visibility
  },

  [SHOW_CONFIRMATION_PAGE](state: State, visibility: boolean) {
    state.page.confirmation.visible = visibility
  },

  [CONFIRM_ELIGIBILITY_CHECK](state: State, confirmed: boolean) {
    state.page.eligibilityCheck.confirmed = confirmed
  },

  [CONFIRM_DETAILS](state: State, confirmed: boolean) {
    state.page.details.confirmed = confirmed
  },

  [CONFIRM_AUTHORISE](state: State, confirmed: boolean) {
    state.page.authorise.confirmed = confirmed
  },

  [CONFIRM_CONFIRMED](state: State, confirmed: boolean) {
    state.page.confirmation.confirmed = confirmed
  },

  [SET_IS_ELIGIBLE](state: State, isEligible: boolean) {
    state.meta.eligibilityCheck.hasChosen = true
    state.meta.eligibilityCheck.isEligible = isEligible
  },

  [SET_DETAILS_REFERENCE](state: State, reference: string) {
    state.meta.details.reference = reference
  },

  [SET_AUTHORISER](state: State, authoriser: string) {
    state.meta.authorise.authoriser = authoriser
  },
}
