<script setup lang="ts">
import { ref } from 'vue'
import DashNavBar from '@/components/generic/DashNavBar/index.vue'
import SideNav from '@/components/generic/SideNav/index.vue'

const dashboard = ref(true)
</script>

<template>
  <div>
    <DashNavBar :dashboard="dashboard" />
    <div class="d-flex">
      <SideNav />
      <main id="content" class="content">
        <router-view v-slot="{ Component }">
          <Transition name="page-fade" mode="out-in">
            <component :is="Component" />
          </Transition>
        </router-view>
      </main>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#content {
  width: 100%;
  padding-right: var(--spacer);
  padding-left: var(--spacer);
  margin-right: auto;
  margin-left: auto;
  margin-top: var(--grid-gutter-width);
}
</style>
