<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  infoClass?: string
  button?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  infoClass: '',
  button: false,
})

const emit = defineEmits(['close'])

const classInfo = computed(() => ({
  authorised: props.infoClass === 'authorised',
  responsible: props.infoClass === 'responsible',
  sources: props.infoClass === 'sources',
}))

const close = () => {
  emit('close')
}
</script>

<template>
  <div :class="classInfo" class="info-box-wrapper" @click.stop>
    <slot name="arrow"></slot>
    <slot name="content"></slot>
    <button
      v-if="button"
      id="close"
      class="btn-primary btn btn-sm info-box-btn"
      @click.prevent="close"
    >
      {{ $t('Generic.InfoBox.CloseButtonText') }}
    </button>
  </div>
</template>
