<script setup lang="ts">
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useForm } from 'vee-validate'
import { SET_FIRST_NAME, SET_LAST_NAME } from '@/store/mutation-types'
import { userInformationSchema } from '@/types'

const store = useStore()

const { handleSubmit, errors, defineField } = useForm({
  validationSchema: userInformationSchema,
})
const [firstname] = defineField('firstname')
const [lastname] = defineField('lastname')

const loading = ref(false)

const user = computed(() => store.getters.user)

const setFirstName = (e: Event) => {
  store.commit(SET_FIRST_NAME, (e.target as HTMLInputElement).value)
}

const setLastName = (e: Event) => {
  store.commit(SET_LAST_NAME, (e.target as HTMLInputElement).value)
}

const putUserDetails = handleSubmit(async (values) => {
  loading.value = true

  try {
    await store.dispatch('putUserDetails', {
      firstname: values.firstname,
      lastname: values.lastname,
    })
    store.dispatch('fetchCurrentUser')
  } catch (error) {
    console.error(error)
  }

  document.getElementById('closeModal')?.click()
  loading.value = false
})
</script>

<template>
  <div
    v-if="user"
    id="editProfileModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="editProfileModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="editProfileModalTitle" class="modal-title">
            {{ $t('Generic.EditProfileModal.Title') }}
          </h5>
          <button
            id="closeModal"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            :aria-label="$t('Generic.EditProfileModal.Form.AriaLabel')"
            novalidate
            spellcheck="false"
            role="form"
            class="col-sm-8 mx-auto"
            @submit.prevent="putUserDetails"
          >
            <div class="mb-3">
              <label for="firstname" class="mb-1 fw-semibold">
                {{ $t('Generic.EditProfileModal.Form.FirstNameInput.Label') }}
              </label>
              <input
                v-model="firstname"
                id="firstname"
                :class="{ input: true, 'is-invalid': errors.firstname?.length }"
                :placeholder="
                  $t('Generic.EditProfileModal.Form.FirstNameInput.Placeholder')
                "
                type="text"
                class="form-control"
                name="firstname"
                aria-describedby="firstname"
                @input="setFirstName"
              />
              <span
                v-show="errors.firstname?.length"
                class="text-danger small words-error"
              >
                {{ errors.firstname }}
              </span>
            </div>

            <div class="mb-3">
              <label for="lastname" class="mb-1 fw-semibold">
                {{ $t('Generic.EditProfileModal.Form.LastNameInput.Label') }}
              </label>
              <input
                v-model="lastname"
                id="lastname"
                :class="{ input: true, 'is-invalid': errors.lastname?.length }"
                :placeholder="
                  $t('Generic.EditProfileModal.Form.LastNameInput.Placeholder')
                "
                class="form-control"
                name="lastname"
                aria-describedby="lastname"
                @input="setLastName"
              />
              <span
                v-show="errors.lastname?.length"
                class="text-danger small words-error"
              >
                {{ errors.lastname }}
              </span>
            </div>

            <div>
              <button
                :aria-label="
                  $t('Generic.EditProfileModal.Form.Button.AriaLabel')
                "
                :disabled="loading"
                class="btn btn-primary btn-sm btn-block"
                type="submit"
              >
                <div v-if="loading" class="spinner loading" role="alert"></div>
                <span v-else id="editBtnText">
                  {{ $t('Generic.EditProfileModal.Form.Button.Text') }}
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
