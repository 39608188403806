import {
  TOGGLE_MOBILE_MENU,
  CLOSE_MOBILE_MENU,
  GLOBAL_ERROR,
  TOGGLE_MENU,
  CLOSE_MENU,
  TOGGLE_FEED_MENU,
  CLOSE_FEED_MENU,
  MAINTENANCE,
} from '@/store/mutation-types'

const document = window.document

export default {
  [TOGGLE_MOBILE_MENU](state: any) {
    const sideNav = document.querySelector('.side-nav')

    if (!sideNav) return

    document.body.classList.toggle('side-nav-open')
    document.body.classList.toggle('side-nav-closed')

    state.sidebarIsOpen = !state.sidebarIsOpen
  },

  [CLOSE_MOBILE_MENU](state: any) {
    document.body.classList.remove('side-nav-open')
    document.body.classList.add('side-nav-closed')
    state.sidebarIsOpen = false
  },

  [GLOBAL_ERROR](state: any, payload: any) {
    state.globalError = payload
  },

  [TOGGLE_MENU](state: any) {
    state.menu = !state.menu
  },

  [CLOSE_MENU](state: any) {
    state.menu = false
  },

  [TOGGLE_FEED_MENU](state: any) {
    state.feedMenu = !state.feedMenu
  },

  [CLOSE_FEED_MENU](state: any) {
    state.feedMenu = false
  },

  [MAINTENANCE](state: any, payload: any) {
    state.maintenance = payload
  },
}
