<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { REMOVE_NOTIFICATION_FROM_ARRAY } from '@/store/mutation-types'
import FeedItem from '@/components/generic/DashNavBar/FeedItem.vue'
import { NotificationSeverity, NotificationCategory } from '@/types'
import type { Notification } from '@/types'

const store = useStore()
const route = useRoute()
const { t } = useI18n()

const errorNotifications = ref<Notification[]>([])
const infoNotifications = ref<Notification[]>([])
const sourceNotification = ref<Notification>({
  id: 'noSource',
  time: new Date().toISOString(),
  severity: NotificationSeverity.Error,
  category: NotificationCategory.DonationSources,
  message: t('Generic.DashNavBar.NotificationFeed.SourceNotificationMessage'),
  description: t('Generic.DashNavBar.NotificationFeed.SourceNotificationDesc'),
})

const noNotifications = computed(() => store.getters.notifications.length === 0)
const notifications = computed(() => store.getters.notifications)
const sources = computed(() => store.getters.sources)
const charityId = computed(() => route.params.id)

const clearAll = () => {
  notifications.value.forEach((notification: Notification) => {
    if (notification.severity !== NotificationSeverity.Error) {
      store.dispatch('deleteNotification', {
        charityId: charityId.value,
        id: notification.id,
      })
      store.commit(REMOVE_NOTIFICATION_FROM_ARRAY, notification.id)
    }
  })
  emit('close')
}

const sortNotifications = async () => {
  try {
    if (sources.value && sources.value.length === 0) {
      errorNotifications.value.push(sourceNotification.value)
    }

    if (await notifications.value) {
      await notifications.value.forEach((notification: Notification) => {
        if (notification.severity === NotificationSeverity.Error) {
          errorNotifications.value.push(notification)
        } else {
          infoNotifications.value.push(notification)
        }
      })
    }

    errorNotifications.value.sort((a, b) => {
      const valA = Date.parse(a.time)
      const valB = Date.parse(b.time)
      return valB - valA
    })
    infoNotifications.value.sort((a, b) => {
      const valA = Date.parse(a.time)
      const valB = Date.parse(b.time)
      return valB - valA
    })
  } catch (error) {
    console.error(error)
  }
}

onMounted(() => {
  sortNotifications()
})

const emit = defineEmits(['close'])
</script>

<template>
  <div class="feed-wrapper" @click.stop>
    <header
      class="feed-header d-flex justify-content-between align-items-center"
    >
      <h5 class="mb-0">
        {{ $t('Generic.DashNavBar.NotificationFeed.Header') }}
      </h5>
      <a
        v-if="infoNotifications && infoNotifications.length > 0"
        id="feedMark"
        href="#"
        class="mark-all"
        @click.prevent="clearAll"
      >
        {{ $t('Generic.DashNavBar.NotificationFeed.ClearButton') }}
      </a>
    </header>

    <section
      v-if="noNotifications"
      id="noNotificationFeed"
      data-testid="no-notification"
    >
      <article class="p-3">
        <h6>
          {{ $t('Generic.DashNavBar.NotificationFeed.NoNotificationMessage') }}
        </h6>
        <p class="mb-0">
          {{ $t('Generic.DashNavBar.NotificationFeed.NoNotificationDesc') }}
        </p>
      </article>
    </section>

    <section v-for="notification in errorNotifications" :key="notification.id">
      <FeedItem
        :notification="notification"
        :aria-label="
          $t(
            'Generic.DashNavBar.NotificationFeed.ErrorNotificationFeedAriaLabel'
          )
        "
        data-testid="error-notification"
        @close="$emit('close')"
      />
    </section>

    <section v-for="notification in infoNotifications" :key="notification.id">
      <FeedItem
        :notification="notification"
        :aria-label="
          $t(
            'Generic.DashNavBar.NotificationFeed.InfoNotificationFeedAriaLabel'
          )
        "
        data-testid="info-notification"
        @close="$emit('close')"
      />
    </section>
  </div>
</template>

<style lang="scss" scoped>
.feed-wrapper {
  position: absolute;
  width: 400px;
  height: unset;
  max-height: 710px;
  top: 49px;
  right: 0;
  border: 0;
  overflow-y: auto;
  -webkit-overflow-y: auto;
  margin: 0;
  padding: 0;
  display: inline-grid;

  @media (max-width: 576px) {
    width: 88vw;
    right: 0;
    top: 64px;
    overflow: none;
  }
}

.feed-header {
  height: 50px;
  padding: 15px;
  border-bottom: solid 1px var(--light-grey);
}

.mark-all {
  color: var(--black);
  font-size: 10px;
  font-weight: bold;
  z-index: 1;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.2s ease;
  white-space: nowrap;
  text-align: right;
  transition: all 0.2s;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;

  &:after {
    content: '';
  }

  &:hover {
    color: var(--mid-grey);
    width: 150px;

    &:after {
      content: ' ALL NOTIFICATIONS';
    }
  }
}
</style>
