const isDev = import.meta.env.VITE_NODE_ENV !== 'production'

const debug = (message: any, value: any = null, type: any = 'log') => {
  if (isDev) {
    // @ts-ignore
    console[type](message, value)
  }
}

export default debug
