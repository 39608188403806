export default {
  config: (state: any) => state.app.config,
  authenticated: (state: any) => state.auth.authenticated,
  globalError: (state: any) => state.app.globalError,
  user: (state: any) => state.charity.user,
  menu: (state: any) => state.app.menu,
  sidebarIsOpen: (state: any) => state.app.sidebarIsOpen,
  feedMenu: (state: any) => state.app.feedMenu,
  sources: (state: any) => state.charity.donationSources,
  donationSource: (state: any) => state.charity.donationSource,
  sourceDetailsModalVisibility: (state: any) =>
    state.charity.sourceDetailsModalVisibility,
  donationSourceSetupModalVisibility: (state: any) =>
    state.charity.donationSourceSetupModalVisibility,
  claims: (state: any) => state.charity.claims,
  claimSummary: (state: any) => state.charity.claimSummary,
  declarations: (state: any) => state.charity.declarations,
  totals: (state: any) => state.charity.totals,
  charityName: (state: any) => state.charity.charityName,
  notifications: (state: any) => state.charity.notifications,
  auth0Token: (state: any) => state.auth.idToken,
}
