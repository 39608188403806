import { createStore, createLogger } from 'vuex'
import app from '@/store/modules/app'
import auth from '@/store/modules/auth'
import charity from '@/store/modules/charity'
import donationSourceSetup from '@/store/modules/donation-source-setup'
import getters from '@/store/getters'

const store = createStore({
  modules: {
    app,
    auth,
    charity,
    donationSourceSetup,
  },
  state: {},
  mutations: {},
  getters,
  plugins: import.meta.env.MODE !== 'production' ? [createLogger()] : [],
})

export default store
