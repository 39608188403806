<script setup lang="ts">
import { onBeforeMount, onUnmounted } from 'vue'
import NavBar from '@/components/generic/NavBar/index.vue'

onBeforeMount(() => {
  document.body.classList.add('on-boarding')
})

onUnmounted(() => {
  document.body.classList.remove('on-boarding')
})
</script>

<template>
  <div class="auth-layout">
    <NavBar />
    <div class="main">
      <div class="auth-content">
        <router-view role="main"></router-view>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.auth-layout {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .main {
    margin: 0;
    height: 100%;

    .auth-content {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--white);
    }
  }
}
</style>
