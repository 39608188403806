import { DateTime } from 'luxon'

export const TOTAL_DECLARATIONS = 10002

export const subtractMonthFromDate = (date: string, months: number) => {
  return DateTime.fromISO(date, { zone: 'utc' }).minus({ months }).toISO()
}

export const claims = {
  data: [
    /**
     * Current 'claim'
     */
    {
      id: '1',
      invoiceId: '',
      date: null,
      countDeclarations: 0,
      totalDonations: 0,
      totalGiftAid: 0,
      overclaimAmount: 0,
      status: 'building',
    },
    /**
     * A Claim that is ready but hasn't been invoiced
     */
    {
      id: '2',
      invoiceId: '',
      date: subtractMonthFromDate('2021-07-20T20:17:00', 1),
      countDeclarations: TOTAL_DECLARATIONS,
      totalDonations: 156000,
      totalGiftAid: 156000 / 4,
      overclaimAmount: 620,
      status: 'ready',
    },
    /**
     * A claim that has been invoiced, but not filed yet
     */
    {
      id: '3',
      invoiceId: 'inv_123',
      date: subtractMonthFromDate('2021-07-20T20:17:00', 2),
      countDeclarations: TOTAL_DECLARATIONS,
      totalDonations: 156000,
      totalGiftAid: 156000 / 4,
      overclaimAmount: 128,
      status: 'invoiced',
    },
    /**
     * A claim that is invoiced and filed
     */
    {
      id: '4',
      invoiceId: 'inv_456',
      date: subtractMonthFromDate('2021-07-20T20:17:00', 3),
      countDeclarations: TOTAL_DECLARATIONS,
      totalDonations: 489645,
      totalGiftAid: 489645 / 4,
      overclaimAmount: 0,
      status: 'filed',
    },
  ],
}
