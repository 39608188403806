<template>
  <div class="sa-maintenance">
    <section class="sa-maintenance__content">
      <img
        id="swifty"
        class="mb-4"
        alt="Swifty"
        src="@/assets/images/swifty-maintenance.svg"
      />
      <img
        id="swiftaid-logo"
        class="mb-3"
        alt="Swiftaid by Streeva logo"
        src="@/assets/images/swiftaid-by-streeva-logo.svg"
      />
      Swiftaid is currently under maintenance.
      <br />
      Please check back soon.
    </section>
  </div>
</template>

<style lang="scss" scoped>
img {
  display: block;
  margin: auto;
}

#swifty {
  width: 80px;
}

#swiftaid-logo {
  height: 43px;
}

.sa-maintenance {
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__content {
    text-align: center;
  }
}
</style>
