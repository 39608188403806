import axios from 'axios'
import router from '@/router'
import type { Commit, Dispatch } from 'vuex'
import {
  RESET_DONATION_SOURCE_SETUP,
  SET_IS_ADDING_NEW_SOURCE,
  SET_DONATION_SOURCE_PROVIDER,
  SET_PAGES,
  SET_PROVIDER_PAGES,
  SET_PROGRESS_METER_VISIBILITY,
  SET_CURRENT_PAGE,
  SET_NEXT_BUTTON_IS_DISABLED,
  SET_ERROR,
  HIDE_ALL_PAGES,
  SHOW_ELIGIBILITY_CHECK_PAGE,
  SHOW_DETAILS_PAGE,
  SHOW_AUTHORISE_PAGE,
  SHOW_CONFIRMATION_PAGE,
  CONFIRM_ELIGIBILITY_CHECK,
  CONFIRM_DETAILS,
  CONFIRM_AUTHORISE,
  CONFIRM_CONFIRMED,
  SET_IS_ELIGIBLE,
  SET_DETAILS_REFERENCE,
  SET_AUTHORISER,
} from '@/store/mutation-types'
import {
  CONFIRMATION_PAGE,
  ELIGIBILITY_CHECK_PAGE,
  DETAILS_PAGE,
  AUTHORISE_PAGE,
} from './index'

const cyclePagesArray = (statePages: any) => {
  const pages = [...statePages]
  const shiftedPage = pages.shift()
  pages.push(shiftedPage)
  return pages
}

export default {
  resetDonationSourceSetup({ commit }: { commit: Commit }) {
    commit(RESET_DONATION_SOURCE_SETUP)
  },

  initPages({ commit }: { commit: Commit }) {
    commit(SET_PROVIDER_PAGES)
  },

  initProgressMeterVisibility({
    state,
    commit,
  }: {
    state: any
    commit: Commit
  }) {
    commit(SET_PROGRESS_METER_VISIBILITY, state.pages.length > 3)
  },

  showFirstPage({ state, dispatch }: { state: any; dispatch: Dispatch }) {
    dispatch('showPage', state.pages[0])
  },

  initSetup({ dispatch }: { dispatch: Dispatch }, provider: any) {
    dispatch('resetDonationSourceSetup')
    dispatch('setDonationSourceProvider', provider)
    dispatch('initPages')
    dispatch('initProgressMeterVisibility')
    dispatch('showFirstPage')
  },

  setDonationSourceProvider({ commit }: { commit: Commit }, provider: any) {
    commit(SET_DONATION_SOURCE_PROVIDER, provider)
  },

  cyclePages({ state, commit }: { state: any; commit: Commit }) {
    commit(SET_PAGES, cyclePagesArray(state.pages))
  },

  nextPage({
    state,
    dispatch,
    commit,
  }: {
    state: any
    dispatch: Dispatch
    commit: Commit
  }) {
    if (state.currentPage === CONFIRMATION_PAGE) return // close modal
    dispatch('setPageConfirmation', state.pages[0])
    dispatch('cyclePages')
    commit(SET_CURRENT_PAGE, state.pages[0])
    dispatch('showPage', state.pages[0])
    dispatch('disableNextButton')
  },

  showPage({ commit }: { commit: Commit }, pageName: string) {
    commit(HIDE_ALL_PAGES)
    commit(SET_CURRENT_PAGE, pageName)
    switch (pageName) {
      case ELIGIBILITY_CHECK_PAGE:
        commit(SHOW_ELIGIBILITY_CHECK_PAGE, true)
        break
      case DETAILS_PAGE:
        commit(SHOW_DETAILS_PAGE, true)
        break
      case AUTHORISE_PAGE:
        commit(SHOW_AUTHORISE_PAGE, true)
        break
      case CONFIRMATION_PAGE:
        commit(SET_PROGRESS_METER_VISIBILITY, false)
        commit(SHOW_CONFIRMATION_PAGE, true)
        break
      default:
        console.log('Page not specified')
    }
  },

  setPageConfirmation({ dispatch }: { dispatch: Dispatch }, pageName: string) {
    switch (pageName) {
      case ELIGIBILITY_CHECK_PAGE:
        dispatch('confirmEligibilityCheck')
        break
      case DETAILS_PAGE:
        dispatch('confirmDetails')
        break
      case AUTHORISE_PAGE:
        dispatch('confirmAuthorise')
        break
      case CONFIRMATION_PAGE:
        dispatch('confirmConfirmed')
        break
      default:
        console.log('Page not specified')
    }
  },

  enableNextButton({ commit }: { commit: Commit }) {
    commit(SET_NEXT_BUTTON_IS_DISABLED, false)
  },

  disableNextButton({ commit }: { commit: Commit }) {
    commit(SET_NEXT_BUTTON_IS_DISABLED, true)
  },

  confirmEligibilityCheck({ commit }: { commit: Commit }) {
    commit(CONFIRM_ELIGIBILITY_CHECK, true)
  },

  confirmDetails({ commit }: { commit: Commit }) {
    commit(CONFIRM_DETAILS, true)
  },

  confirmAuthorise({ commit }: { commit: Commit }) {
    commit(CONFIRM_AUTHORISE, true)
  },

  confirmConfirmed({ commit }: { commit: Commit }) {
    commit(CONFIRM_CONFIRMED, true)
  },

  setIsEligible({ dispatch, commit }: { dispatch: Dispatch; commit: Commit }) {
    dispatch('enableNextButton')
    commit(SET_IS_ELIGIBLE, true)
  },

  setIsNotEligible({
    dispatch,
    commit,
  }: {
    dispatch: Dispatch
    commit: Commit
  }) {
    dispatch('disableNextButton')
    commit(SET_IS_ELIGIBLE, false)
  },

  setReference(
    { dispatch, commit }: { dispatch: Dispatch; commit: Commit },
    payload: { isValid: any; reference: any }
  ) {
    if (payload.isValid) {
      dispatch('enableNextButton')
    } else {
      dispatch('disableNextButton')
    }
    commit(SET_DETAILS_REFERENCE, payload.reference)
  },

  setAuthoriser({ commit }: { commit: Commit }, authoriser: any) {
    commit(SET_AUTHORISER, authoriser)
  },

  async postDonationSource({
    state,
    commit,
    dispatch,
  }: {
    state: any
    commit: Commit
    dispatch: Dispatch
  }) {
    const { referenceByHmrcCustomerId } = state.donationSourceProviderMeta

    if (!router.currentRoute.value.params.id) {
      console.error('Missing id in route params')
      commit(SET_ERROR, { status: true })
      return
    }

    if (!state.donationSourceProvider) {
      console.error('Missing donation source provider')
      commit(SET_ERROR, { status: true })
      return
    }

    if (!referenceByHmrcCustomerId && !state.meta.details.reference) {
      console.error('Missing customer reference')
      commit(SET_ERROR, { status: true })
      return
    }

    if (!state.meta.authorise.authoriser) {
      console.error('Missing authoriser')
      commit(SET_ERROR, { status: true })
      return
    }

    // Build payload
    const payload = {
      provider: state.donationSourceProvider,
      authorisingOfficial: state.meta.authorise.authoriser,
      referenceByHmrcCustomerId:
        state.donationSourceProviderMeta.referenceByHmrcCustomerId,
      providerReference: state.meta.details.reference ?? '',
    }

    if (payload.providerReference === '') {
      delete payload.providerReference
    }

    // Send payload to API
    try {
      const response = await axios.post(
        `/me/charity/${router.currentRoute.value.params.id}/donationSources/creationRequests`,
        payload
      )
      await dispatch('fetchCharitySummary', router.currentRoute.value.params.id)
      commit(SET_IS_ADDING_NEW_SOURCE, false)
      commit(SET_ERROR, { status: false })
      return response
    } catch (error: any) {
      commit(SET_ERROR, {
        status: true,
        message: error.response.data,
        code: error.response.status,
      })
      console.error('An error occurred posting a donation source')
    }
  },
}
