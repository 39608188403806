import auth0 from 'auth0-js'
import { AUTH_CONFIG } from './auth0-variables'
import { HELPERS } from '../../helpers'

export default new auth0.WebAuth({
  domain: AUTH_CONFIG.domain,
  clientID: AUTH_CONFIG.clientId,
  responseType: 'token id_token',
  redirectUri: HELPERS.callbackUrl,
  audience: AUTH_CONFIG.audience,
  scope: AUTH_CONFIG.scope,
})
