<template>
  <div class="clearance"></div>
</template>

<style lang="scss" scoped>
.clearance {
  height: 70px;

  @media (min-width: 576px) {
    height: 110px;
  }

  @media print {
    display: none;
  }
}
</style>
