import mutations from './mutations'
import actions from './actions'

const isAuthenticated = function () {
  const expiresAt = JSON.parse(localStorage.getItem('expires_at') ?? '0')
  return (
    new Date().getTime() < expiresAt &&
    localStorage.getItem('loggedIn') === 'true'
  )
}

export default {
  namespaced: false,
  state: {
    authenticated: isAuthenticated(),
    idToken: null,
    authEmail: null,
    btnLoading: false,
    formSubmitted: false,
  },
  mutations,
  actions,
}
