import {
  UPDATE_AUTH,
  SET_AUTH,
  SET_TOKEN,
  SET_AUTH_EMAIL,
  SET_BUTTON_LOADING_STATUS,
  SET_FORM_SUBMITTED_STATUS,
} from '@/store/mutation-types'

export default {
  [UPDATE_AUTH](state: any, payload: any) {
    state.authenticated = payload
  },

  [SET_AUTH](state: any, payload: any) {
    state.authenticated = payload
  },

  [SET_TOKEN](state: any, payload: any) {
    state.idToken = payload
  },
  [SET_AUTH_EMAIL](state: any, payload: any) {
    state.authEmail = payload
  },
  [SET_BUTTON_LOADING_STATUS](state: any, payload: any) {
    state.btnLoading = payload
  },
  [SET_FORM_SUBMITTED_STATUS](state: any, payload: any) {
    state.formSubmitted = payload
  },
}
