<script setup lang="ts">
defineProps({
  notifications: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['toggleFeed'])
</script>

<template>
  <div @click="emit('toggleFeed')">
    <div class="notification-image-placeholder">
      <img
        id="bellImg"
        :alt="$t('Generic.DashNavBar.NotificationPlaceholder.ImageAlt')"
        class="bell-image"
        src="@/assets/images/bell.svg"
      />
      <div v-if="notifications" class="notification">
        {{ notifications }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bell-image {
  margin-top: 8px;
}
.notification-image-placeholder {
  overflow: hidden;
  width: 40px;
  height: 40px;
  background-color: var(--light-grey);
  border-radius: 100%;
  transition: background-color 0.2s ease;

  &:hover {
    cursor: pointer;
    background-color: var(--mid-grey);
  }
}
.notification {
  height: 17px;
  width: 17px;
  border: solid 1px var(--white);
  border-radius: 100%;
  background-color: var(--theme-red);
  position: absolute;
  top: -5px;
  right: -5px;
  color: var(--white);
  font-size: 10px;
  text-align: center;
  font-weight: bold;
}
</style>
