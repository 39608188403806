<script setup lang="ts">
import { computed, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import type * as Bootstrap from 'bootstrap'
import ProfilePlaceholder from '@/components/generic/NavBar/ProfilePlaceholder.vue'
import ProfileMenu from '@/components/generic/NavBar/ProfileMenu.vue'
import InfoBox from '@/components/generic/InfoBox.vue'
import { TOGGLE_MENU } from '@/store/mutation-types'

const store = useStore()
const router = useRouter()

const bootstrap = inject('bootstrap') as typeof Bootstrap
const editProfileModal = new bootstrap.Modal('#editProfileModal')

const authenticated = computed(() => store.getters.authenticated)
const user = computed(() => store.getters.user)
const menu = computed(() => store.getters.menu)
const isDetails = computed(() => router.currentRoute.value.name === 'Details')

const toggleMenu = () => store.commit(TOGGLE_MENU)

const edit = () => {
  toggleMenu()
  editProfileModal.toggle()
}

const logout = async () => {
  toggleMenu()
  await store.dispatch('auth0Logout')
}
</script>

<template>
  <nav class="navbar">
    <div class="container-fluid mx-3">
      <a class="navbar-brand" href="https://swiftaid.co.uk/charity/">
        <img
          :alt="$t('Generic.NavBar.NavLogoImageAlt')"
          src="@/assets/images/logo.svg"
        />
      </a>

      <div
        class="navbar-profile-wrapper position-relative d-flex justify-content-center"
      >
        <ProfilePlaceholder
          v-if="authenticated && !isDetails"
          id="navbarProfile"
          :aria-label="$t('Generic.NavBar.ProfileMenuAriaLabel')"
          data-testid="profile-placeholder"
          @click="toggleMenu"
        />
        <transition name="menu-fade">
          <InfoBox v-if="menu" id="profileMenu" @close="toggleMenu">
            <template #content>
              <ProfileMenu
                :firstname="user.firstname"
                :lastname="user.lastname"
                @edit="edit"
                @logout="logout"
              />
            </template>
          </InfoBox>
        </transition>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.navbar {
  .navbar-container {
    width: 960px;
    margin: auto;
  }
}

a {
  text-decoration: none;
  font-weight: 600;
  color: var(--black);
  font-size: 20px;
}
</style>
