export default {
  donationSourceProvider: (state: {
    donationSourceProvider: any
    donationSourceProviderMeta: {
      slug: any
      translationKey: any
      logo: any
      brandColor: any
      inputPlaceholder: any
      inputMask: any
      inputValidation: any
      upperCase: any
      informationShareList: any
      referenceByHmrcCustomerId: any
    }
    helpPage: any
  }) => {
    return {
      name: state.donationSourceProvider,
      slug: state.donationSourceProviderMeta.slug,
      translationKey: state.donationSourceProviderMeta.translationKey,
      logo: state.donationSourceProviderMeta.logo,
      brandColor: state.donationSourceProviderMeta.brandColor,
      inputPlaceholder: state.donationSourceProviderMeta.inputPlaceholder,
      inputMask: state.donationSourceProviderMeta.inputMask,
      inputValidation: state.donationSourceProviderMeta.inputValidation,
      upperCase: state.donationSourceProviderMeta.upperCase,
      informationShareList:
        state.donationSourceProviderMeta.informationShareList,
      helpPage: state.helpPage,
      referenceByHmrcCustomerId:
        state.donationSourceProviderMeta.referenceByHmrcCustomerId,
    }
  },
}
