import { DateTime } from 'luxon'

export default {
  formatTime(date: string) {
    // Returns '22:00'
    return DateTime.fromISO(date, { zone: 'utc' }).toFormat('HH:mm')
  },
  formatDate(date: string) {
    // Returns '22 June'
    return DateTime.fromISO(date, { zone: 'utc' }).toFormat('dd MMM')
  },
  formatToHumanDate(date: string) {
    // Returns '22/06/2024'
    return DateTime.fromISO(date, { zone: 'utc' }).toLocaleString(DateTime.DATE_SHORT)
  },
  poundSign(value: number) {
    const val = (value / 100).toFixed(2)
    return '£' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  formatPrice(value: number) {
    const val = (value / 100).toFixed(2)
    const str = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return str.slice(0, -2)
  },
  formatDecimal(value: number) {
    const val = (value / 100).toFixed(2)
    const str = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return str.slice(-2)
  },
  formatAmount(amount: string) {
    if (amount === '') {
      return 'Any'
    }
    return amount
      .split(',')
      .map((val) => {
        if (val.includes('-')) {
          return val
            .split('-')
            .map((no) => {
              return '£' + no
            })
            .join('-')
        }
        return '£' + val + ''
      })
      .join(', ')
  },
  formatFees(fees: { type: string; amount: string }[]) {
    if (fees.length === 0) {
      return 'N/A'
    }
    return fees
      .map((fee) => {
        if (fee.type === 'fixed') {
          return fee.amount + 'p'
        } else {
          return fee.amount + '%'
        }
      })
      .join(' + ')
  },
}

export const formatMoney = (value: any) => {
  try {
    const values = Number.isInteger(parseInt(value, 10))
      ? (value / 100).toFixed(2).split('.')
      : ['0', '00']
    return {
      pounds: values[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      pence: values[1],
    }
  } catch (e) {
    console.log(e)
    return {
      pounds: '0',
      pence: '00',
    }
  }
}

export const dateToShortString = (date: string) => {
  // Returns '22 Jun 24'
  return DateTime.fromISO(date, { zone: 'utc' }).toFormat('dd LLL yy')
}

export const formatTime = (date: string) => {
  // Returns '22:00'
  return DateTime.fromISO(date, { zone: 'utc' }).toFormat('HH:mm')
}

