<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import menuItems from '@/components/generic/SideNav/menu-items.json'
import { TOGGLE_MOBILE_MENU, CLOSE_MOBILE_MENU } from '@/store/mutation-types'

const store = useStore()
const router = useRouter()

const charityName = computed(() => store.getters.charityName)

const toggleSidebar = () => {
  store.commit(TOGGLE_MOBILE_MENU)
}

const toUserDashboard = () => {
  store.commit(TOGGLE_MOBILE_MENU)
  router.push({ name: 'Dashboard' })
}

onMounted(() => {
  store.commit(CLOSE_MOBILE_MENU)
})
</script>

<template>
  <aside class="side-nav" @click="toggleSidebar()">
    <ul class="side-nav__menu" data-testid="side-menu">
      <li class="side-nav__menu-item d-lg-none">
        <router-link
          :to="{ name: 'Dashboard' }"
          class="side-nav__link sa-logo brand-link"
        >
          <div class="brand-link__image">
            <img
              :alt="$t('Generic.SideBar.NavLogoImageAlt')"
              src="@/assets/images/logo-sa.svg"
              height="24px"
            />
          </div>
          <div class="brand-link__divider"></div>
          <div v-if="charityName" class="brand-link__charity-name">
            {{ charityName }}
          </div>
        </router-link>
      </li>

      <li
        v-for="item in menuItems"
        :key="item.name"
        class="side-nav__menu-item"
      >
        <router-link :to="{ name: item.to }" class="side-nav__link">
          <i :class="item.icon"></i>
          <span>{{ $t(item.name) }}</span>
        </router-link>
      </li>

      <li class="side-nav__menu-item" @click.prevent="toUserDashboard">
        <a class="side-nav__link">
          <i class="fa fa-home fa-fw"></i>
          <span>
            {{ $t('Generic.SideBar.MenuItems.Exit') }}
          </span>
        </a>
      </li>
    </ul>
  </aside>
</template>

<style lang="scss">
.brand-link {
  display: flex;

  &__divider {
    border-left: solid 2px var(--mid-grey);
    height: 40px;
    margin: 0 calc(var(--spacer) * 0.5);
    float: left;
  }

  &__charity-name {
    font-size: 14px;
    font-weight: 700;
    color: var(--black) !important;
  }

  &.side-nav__link:hover {
    background-color: var(--light-grey);
  }
}

.side-nav {
  background-color: rgba(var(--black-rgb), 0.8);
  position: absolute;
  top: var(--navbar-height-sm);
  opacity: 1;
  left: 0;
  width: 100%;
  z-index: 5;
  bottom: 0;
  right: 0;

  @media (min-width: 992px) {
    flex: 0 0 270px;
    position: static;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    display: block;
    margin: 20px 0 0 20px;
    background-color: transparent;
  }

  &__menu {
    background: var(--white);
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
  }

  &__menu-item {
    height: 56px;
    display: block;
    padding-left: 0;
    border-bottom: solid 1px var(--light-grey);
  }

  &__link {
    position: relative;
    height: var(--sidebar-link-height);
    padding-left: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    border-bottom: solid 1px var(--light-grey);
    font-family: var(--main-font-family);
    text-align: center;
    font-size: 17px;
    color: var(--black);
    font-weight: 600;
    transition:
      background-color 0.12s linear,
      color 0.12s linear;

    &:last-child {
      border: none;
    }

    i {
      color: var(--swiftaid-blue);
      padding-right: 35px;
    }

    &:hover,
    &.router-link-active {
      color: var(--white);
      background-color: var(--swiftaid-blue);
      text-decoration: none;

      i {
        color: var(--white);
      }
    }
  }
}

.side-nav-closed {
  .side-nav {
    display: none;

    @media (min-width: 992px) {
      display: block;
    }
  }
}
</style>
