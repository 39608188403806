import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import * as bootstrap from 'bootstrap'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n'
import './services/axios/axiosGlobals'
import { makeServer } from './mock-api-server'
import Maintenance from './layouts/Maintenance.vue'

if (import.meta.env.MODE === 'mockapi') {
  makeServer()
}

if (import.meta.env.VITE_MAINTENANCE) {
  const app = createApp(Maintenance)
  app.mount('#app')
} else {
  const app = createApp(App)

  Sentry.init({
    app,
    dsn: 'https://7c82ead05c5160b9fdd03f9e7caba9c5@o4508121914212352.ingest.de.sentry.io/4508168853061712',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.browserProfilingIntegration(),
    ],
    tracesSampleRate: 0.2,
    profilesSampleRate: 1.0,
  })

  Sentry.setTag('environment', import.meta.env.MODE)

  app.config.globalProperties.$bootstrap = bootstrap
  app.provide('bootstrap', bootstrap)

  app.use(store)
  app.use(router)
  app.use(i18n)
  app.mount('#app')
}
