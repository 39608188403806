<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const showError = ref(false)

const globalError = computed(() => store.getters.globalError)

watch(globalError, () => {
  if (globalError.value) {
    setTimeout(() => {
      showError.value = true
      setTimeout(() => {
        showError.value = false
      }, 5200)
    }, 500)
  }
})
</script>

<template>
  <div
    v-if="globalError"
    class="error-wrapper"
    :class="{ 'show-error': showError }"
    role="alert"
  >
    <p class="error-wrapper__message">
      {{ $t(globalError) }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.error-wrapper {
  position: fixed;
  z-index: 2000;
  top: 0;
  background-color: var(--theme-red);
  height: calc(var(--grid-gutter-width) * 2);
  width: 100%;
  text-align: center;
  box-shadow:
    0 0 25px rgba(0, 0, 0, 0.1),
    0 0 5px rgba(0, 0, 0, 0.3);
  transform: translateY(calc(-1 * var(--grid-gutter-width) * 2));
  transition: transform 0.5s ease-in-out;

  &.show-error {
    transform: translateY(0);
  }

  &__message {
    color: var(--white);
    font-size: var(--font-size-md);
    font-family: var(--main-font-family);
    line-height: calc(var(--grid-gutter-width) * 2);
  }
}
</style>
