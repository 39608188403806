import mutations from './mutations'
import actions from './actions'

export default {
  state: {
    user: {},
    donationSources: null,
    donationSource: null,
    totals: {},
    claims: null,
    claimSummary: {},
    declarations: {},
    charityId: null,
    charityName: null,
    notifications: null,
    declarationsPerPage: 50,
    sourceDetailsModalVisibility: false,
    donationSourceSetupModalVisibility: false,
  },
  mutations,
  actions,
}
