// App
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU'
export const CLOSE_MOBILE_MENU = 'CLOSE_MOBILE_MENU'
export const GLOBAL_ERROR = 'GLOBAL_ERROR'
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const CLOSE_MENU = 'CLOSE_MENU'
export const TOGGLE_FEED_MENU = 'TOGGLE_FEED_MENU'
export const CLOSE_FEED_MENU = 'CLOSE_FEED_MENU'
export const MAINTENANCE = 'MAINTENANCE'

// Auth
export const UPDATE_AUTH = 'UPDATE_AUTH'
export const SET_AUTH = 'SET_AUTH'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_AUTH_EMAIL = 'SET_AUTH_EMAIL'
export const SET_BUTTON_LOADING_STATUS = 'SET_BUTTON_LOADING_STATUS'
export const SET_FORM_SUBMITTED_STATUS = 'SET_FORM_SUBMITTED_STATUS'

// Charity
export const REMOVE_NOTIFICATION_FROM_ARRAY = 'REMOVE_NOTIFICATION_FROM_ARRAY'
export const SET_TOTAL_DECLARATIONS = 'SET_TOTAL_DECLARATIONS'
export const SET_TOTAL_GIFT_AID = 'SET_TOTAL_GIFT_AID'
export const SET_TOTAL_DONATIONS = 'SET_TOTAL_DONATIONS'
export const SET_CLAIMS = 'SET_CLAIMS'
export const SET_DECLARATIONS = 'SET_DECLARATIONS'
export const SET_CLAIM_SUMMARY = 'SET_CLAIM_SUMMARY'
export const SET_DONATION_SOURCES = 'SET_DONATION_SOURCES'
export const SET_DONATION_SOURCE = 'SET_DONATION_SOURCE'
export const SET_USER = 'SET_USER'
export const SET_FIRST_NAME = 'SET_FIRST_NAME'
export const SET_LAST_NAME = 'SET_LAST_NAME'
export const SET_CHARITY_DETAILS = 'SET_CHARITY_DETAILS'
export const SET_CHARITY = 'SET_CHARITY'
export const SET_CHARITY_ID = 'SET_CHARITY_ID'
export const SET_CHARITY_NAME = 'SET_CHARITY_NAME'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_FRIENDLY_NAME = 'SET_FRIENDLY_NAME'
export const SET_SOURCE_DETAILS_MODAL_VISIBILITY =
  'SET_SOURCE_DETAILS_MODAL_VISIBILITY'
export const SET_DONATION_SOURCE_SETUP_MODAL_VISIBILITY =
  'SET_DONATION_SOURCE_SETUP_MODAL_VISIBILITY'

// Donation source setup
export const RESET_DONATION_SOURCE_SETUP = 'RESET_DONATION_SOURCE_SETUP'
export const SET_IS_ADDING_NEW_SOURCE = 'SET_IS_ADDING_NEW_SOURCE'
export const SET_DONATION_SOURCE_PROVIDER = 'SET_DONATION_SOURCE_PROVIDER'
export const SET_PAGES = 'SET_PAGES'
export const SET_PROVIDER_PAGES = 'SET_PROVIDER_PAGES'
export const SET_PROGRESS_METER_VISIBILITY = 'SET_PROGRESS_METER_VISIBILITY'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_NEXT_BUTTON_IS_DISABLED = 'SET_NEXT_BUTTON_IS_DISABLED'
export const SET_ERROR = 'SET_ERROR'
export const HIDE_ALL_PAGES = 'HIDE_ALL_PAGES'
export const SHOW_ELIGIBILITY_CHECK_PAGE = 'SHOW_ELIGIBILITY_CHECK_PAGE'
export const SHOW_DETAILS_PAGE = 'SHOW_DETAILS_PAGE'
export const SHOW_AUTHORISE_PAGE = 'SHOW_AUTHORISE_PAGE'
export const SHOW_CONFIRMATION_PAGE = 'SHOW_CONFIRMATION_PAGE'
export const CONFIRM_ELIGIBILITY_CHECK = 'CONFIRM_ELIGIBILITY_CHECK'
export const CONFIRM_DETAILS = 'CONFIRM_DETAILS'
export const CONFIRM_AUTHORISE = 'CONFIRM_AUTHORISE'
export const CONFIRM_CONFIRMED = 'CONFIRM_CONFIRMED'
export const SET_IS_ELIGIBLE = 'SET_IS_ELIGIBLE'
export const SET_DETAILS_REFERENCE = 'SET_DETAILS_REFERENCE'
export const SET_AUTHORISER = 'SET_AUTHORISER'
