import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export const ELIGIBILITY_CHECK_PAGE = 'eligibilityCheck'
export const DETAILS_PAGE = 'details'
export const AUTHORISE_PAGE = 'authorise'
export const CONFIRMATION_PAGE = 'confirmation'
export const ALL_PAGES = [
  ELIGIBILITY_CHECK_PAGE,
  DETAILS_PAGE,
  AUTHORISE_PAGE,
  CONFIRMATION_PAGE,
]

export const defaultState = () => {
  return {
    isAddingNewSource: false,
    providerConfig: {},
    donationSourceProvider: '',
    donationSourceProviderMeta: {
      slug: '',
      translationKey: '',
      logo: '',
      brandColor: '',
      inputPlaceholder: '',
      inputValidation: '',
      inputMask: '',
      upperCase: '',
      informationShareList: {
        swiftaidShareWithProvider: [],
        providerShareWithSwiftaid: [],
      },
      referenceByHmrcCustomerId: false,
    },
    currentPage: ALL_PAGES[0],
    pages: [...ALL_PAGES],
    excludedPages: [],
    nextButtonIsDisabled: true,
    error: false,
    errorMessage: '',
    errorCode: '',
    showProgressMeter: false,
    page: {
      eligibilityCheck: {
        visible: true,
        confirmed: false,
      },
      details: {
        visible: false,
        confirmed: false,
      },
      authorise: {
        visible: false,
        confirmed: false,
      },
      confirmation: {
        visible: false,
        confirmed: false,
      },
    },
    meta: {
      eligibilityCheck: {
        hasChosen: false,
        isEligible: false,
      },
      details: {
        reference: '',
      },
      authorise: {
        authoriser: '',
      },
    },
    helpPage: '',
  }
}

export default {
  state: defaultState,
  mutations,
  actions,
  getters,
}
